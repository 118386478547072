import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import ReactionIcon from '@/assets/images/reactions/ReactionIcon.vue';
export default defineComponent({
    components: {
        ReactionIcon
    },
    ...componentI18n('components.topics.reaction_pill'),
    props: {
        reactions: { type: Array, default: () => [] },
        users: { type: Array, default: () => [] },
        reactable: { type: Boolean, default: false },
        type: { type: String, required: true }
    },
    computed: {
        ...mapGetters(['session']),
        reacted() {
            return this.reactions?.some((reaction) => reaction.userId === this.session.id);
        },
        showNumberOfReactions() {
            return this.reactions?.length > 0;
        },
        reactionsText() {
            if (this.showNumberOfReactions) {
                return this.reactions
                    .map((reaction) => this.users.find((u) => u.id === reaction.userId)?.name ?? '')
                    .join(', ')
                    .replace(/,(?=[^,]*$)/, ` ${this.$t('common.and')}`);
            }
            else {
                return null;
            }
        }
    },
    methods: {
        handleClick() {
            this.$emit('click', { type: this.type, target: !this.reacted });
        }
    }
});
