<template>
  <div class="pt-40">
    <section-heading heading="Pattern Library" />

    <section class="flex">
      <div class="flex-1 px-5 -ml-5">
        <inner-heading heading="Typography" />
      </div>
      <div class="flex-1 px-5 -mr-5 mode-dark">
        <inner-heading heading="Typography" />
      </div>
    </section>

    <section>
      <div class="text-2xl">
        <p class="font-bold text-primary-900">Modal heading</p>
      </div>
      <div class="text-lg">
        <div class="font-bold text-primary-900">Radio card name</div>
        <p class="text-primary-600">Drag into new group</p>
        <p class="font-medium">participant list item</p>
        <div class="font-medium text-primary-700">Group heading</div>
      </div>
      <div class="text-sm">
        <div class="font-bold text-primary-500">total 60 min</div>
        <p class="text-primary-600">Drag topic here</p>
        <div>most text in the retro creation</div>
      </div>
      <div class="text-xs">
        <div class="font-medium text-gray-900">Small button text</div>
        <div class="text-gray-400">action item footer / X votes</div>
        <div class="text-primary-400">New Action item / Topic type + color override</div>
        <p class="font-normal text-gray-400 uppercase dark:text-gray-400">Activity highlights</p>
      </div>
    </section>

    <section class="flex">
      <div class="flex-1 px-5 -ml-5">
        <inner-heading heading="Avatars" />
        Avatar list
        <avatar-list
          :users="[
            { name: 'A', avatar: null },
            { name: 'B', avatar: null },
            { name: 'C', avatar: null },
            { name: 'D', avatar: null }
          ]"
        />
      </div>
      <div class="flex-1 px-5 -mr-5 text-white mode-dark">
        <inner-heading heading="Avatars" />
        Avatar list
        <avatar-list
          :users="[
            { name: 'A', avatar: null },
            { name: 'B', avatar: null },
            { name: 'C', avatar: null },
            { name: 'D', avatar: null }
          ]"
        />
      </div>
    </section>

    <section>topics, hover delete/edit</section>
    <section>groups</section>
    <section>action items</section>

    <section class="flex">
      <div class="flex-1 px-5 -ml-5">
        <inner-heading heading="Forms" />
        <div>text inputs</div>

        <input
          class="w-64 text-sm font-medium rounded-full form-input"
          placeholder="Search input"
        />

        <div class="flex h-6">
          <reaction-pill
            v-for="reactionType in ['clap', 'confetti']"
            :key="reactionType"
            :type="reactionType"
            :reactable="true"
          />
          <reaction-pill
            type="rocket"
            :reactable="true"
            :reactions="[{ type: 'rocket', userId: session.id, user: { name: 'You' } }]"
          />
          <reaction-pill
            type="sad"
            :reactable="true"
            :reactions="[{ type: 'sad', user: { id: 13, name: 'User' } }]"
          />
        </div>

        <div class="flex h-6">
          <reaction-pill
            type="clap"
            :reactable="false"
            :reactions="[{ type: 'clap', user: { id: 13, name: 'User' } }]"
          />
        </div>

        topic type
      </div>
      <div class="flex-1 px-5 -mr-5 text-red-500 mode-dark">
        <inner-heading heading="Forms" />
        <div>text inputs</div>

        <input
          class="w-64 text-sm font-medium rounded-full form-input"
          placeholder="Search input"
        />

        <div class="flex h-6">
          <reaction-pill
            v-for="reactionType in ['clap', 'confetti']"
            :key="reactionType"
            :type="reactionType"
            :reactable="true"
          />
          <reaction-pill
            type="rocket"
            :reactable="true"
            :reactions="[{ type: 'rocket', userId: session.id, user: { name: 'You' } }]"
          />
          <reaction-pill
            type="sad"
            :reactable="true"
            :reactions="[{ type: 'sad', user: { id: 13, name: 'User' } }]"
          />
        </div>

        <div class="flex h-6">
          <reaction-pill
            type="clap"
            :reactable="false"
            :reactions="[{ type: 'clap', user: { id: 13, name: 'User' } }]"
          />
        </div>

        topic type
      </div>
    </section>
    <section>
      others: advanced card styles, timers, drag into new group, empty states, gray sidebar
    </section>
    <section>bottom fixed bar</section>
    <section>spacing</section>
  </div>
</template>

<script>
import AvatarList from '@/components/users/AvatarList';
import ReactionPill from '@/components/topics/ReactionPill';

import { mapGetters } from 'vuex';

export default {
  name: 'RetroIndex',
  components: {
    AvatarList,
    ReactionPill
  },
  computed: {
    ...mapGetters(['session'])
  }
};
</script>
