<template>
  <span v-if="type === 'clap'" class="block">👏</span>
  <span v-else-if="type === 'confetti'" class="block">🎉</span>
  <span v-else-if="type === 'rocket'" class="block">🚀</span>
  <span v-else-if="type === 'sad'" class="block">😕</span>
</template>

<script>
export default {
  name: 'ReactionIcon',
  props: {
    type: { type: String, required: true }
  }
};
</script>
